import * as React from 'react';
import { Link } from 'gatsby';
import { gql } from '@urql/core';
import { captureException, withCounter } from 'utils';
import { CatalogLayout } from 'components/CatalogLayout';
import { PageError } from 'components/PageError';
import { AuthRequired } from 'components/AuthRequired';
import { BackButton } from 'components/BackButton';
import { HeadMetaData } from 'components/HeadMetaData';
import FavoriteGrayIcon from 'components/icons/FavoriteGray';
import { ProductCardList, ProductCardListSkeleton } from 'components/ProductCardList';
import { useMyFavoritesPageQuery, useMyFavoritesPageMutation } from 'generated/graphql';
import { useStoreon } from 'hooks';

const ContentWrapper: React.FC<{ favoriteCount?: number }> = ({ children, favoriteCount }) => (
  <CatalogLayout>
    <HeadMetaData title="Любимые товары | Быстрая доставка свежих и вкусных продуктов" />
    <BackButton className="text-2xl font-bold mt-7 mb-11">
      {favoriteCount ? withCounter('Любимые товары', favoriteCount) : 'Любимые товары'}
    </BackButton>
    {children}
  </CatalogLayout>
);

const Favorites = () => {
  const { token } = useStoreon('token');
  const [queryRes, refetchQuery] = useMyFavoritesPageQuery({ requestPolicy: 'cache-and-network' });
  const [, mutate] = useMyFavoritesPageMutation();

  if (queryRes.error) {
    const retry = () => refetchQuery({ requestPolicy: 'network-only' });
    return (
      <CatalogLayout>
        <PageError error={queryRes.error} retry={retry} className="justify-center" />
      </CatalogLayout>
    );
  }

  const noUser = queryRes.data && !queryRes.data.viewer.user;
  if ((queryRes.fetching && !queryRes.data) || (noUser && token)) {
    return (
      <ContentWrapper>
        <ProductCardListSkeleton />
      </ContentWrapper>
    );
  }

  if (!queryRes.data) {
    captureException(new Error(`my favorites page data is null`));
    return null;
  }

  if (!queryRes.data.viewer.user) {
    return (
      <CatalogLayout>
        <AuthRequired />
      </CatalogLayout>
    );
  }

  const handleRemoveFavorites = () => {
    const removeFavorites = async () => {
      try {
        await mutate();
      } catch (error) {
        captureException(error);
        // eslint-disable-next-line
        alert(error.message);
      }
    };

    // eslint-disable-next-line
    if (confirm('Удалить любимые товары?')) {
      removeFavorites();
    }
  };

  const { favoriteProducts } = queryRes.data.viewer.user;

  return (
    <ContentWrapper favoriteCount={favoriteProducts.length}>
      {favoriteProducts.length > 0 ? (
        <>
          <button type="button" onClick={handleRemoveFavorites} className="flex underline hover:no-underline mb-4">
            Удалить все
          </button>
          <ProductCardList products={favoriteProducts} hideProductBadge />
        </>
      ) : (
        <div className="flex flex-col flex-grow justify-center items-center text-center">
          <FavoriteGrayIcon className="mb-6" style={{ width: '80px', height: '72px' }} />
          <h2 className="font-medium text-2xl">Здесь будут храниться ваши любимые товары.</h2>
          <p className="text-lg">Чтобы добавить объект в избранное, нажмите на сердечко в карточке товара.</p>
          <Link
            to="/catalog"
            className="flex items-center h-10 font-medium text-orange-200 bg-orange-100 rounded-md uppercase tracking-wider px-8 mt-5 transition-opacity duration-300 ease-in-out hover:opacity-70"
          >
            В каталог
          </Link>
        </div>
      )}
    </ContentWrapper>
  );
};

export default Favorites;

gql`
  fragment MyFavoritesPage_user on User {
    id
    favoriteProducts {
      id
      ...ProductCardList_data
    }
  }

  query MyFavoritesPageQuery {
    viewer {
      id
      user {
        ...MyFavoritesPage_user
      }
    }
  }

  mutation MyFavoritesPageMutation {
    removeAllFavoriteProducts {
      ... on RemoveAllFavoriteProductsPayload {
        user {
          ...MyFavoritesPage_user
          favoriteProductIds
        }
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
